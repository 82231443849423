import React, { useEffect, useState } from "react";
import { Row, Col, Input, Divider, Select, Switch } from "antd";
import "./StepTwo.css";
import { MaskedInput } from "antd-mask-input";
import InputMask from "react-input-mask";
const StepTwo = (props) => {
  const {
    userData,
    setUserData,
    errorField,
    setErrorField,
    homePhone,
    setHomePhone,
    cellPhone,
    setCellPhone,
    contactPhone,
    setContactPhone,
    acontactPhone,
    setAContactPhone,
    aCellPhone,
    aHomePhone,
    setAHomePhone,
    setACellPhone,
    regType,
    curDomain
  } = props;

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const { Option } = Select;
  return (
    <div className="profile-info-widget">
      <div>
        <span className="title">Step 2: Enter Parent/Guardian Info (As it appears on credit card)</span>
        <Divider></Divider>
      </div>
      <div className="block">
        <div>
          <Row justify="space-between">
            <Col>
              <span className="title">Parent/Guardian</span>
            </Col>
            <Col>
              <span
                className="title"
                style={{ cursor: "pointer", display: "none" }}
              >
                Close
              </span>
            </Col>
          </Row>
          <Divider
            style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
          ></Divider>
        </div>
        <div style={{ marginTop: 20 }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>
                Parent/Guardian First Name*
              </div>
              <Input
                value={userData.p_first_name}
                className={
                  errorField !== undefined &&
                    errorField.includes("p_first_name")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("p_first_name")) {
                    let errors = errorField.filter((x) => x != "p_first_name");
                    setErrorField([...errors]);
                  }
                  setUserData({
                    ...userData,
                    p_first_name: capitalizeFirstLetter(e.target.value),
                  });
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>
                Parent/Guardian Last Name*
              </div>
              <Input
                value={userData.p_last_name}
                className={
                  errorField !== undefined && errorField.includes("p_last_name")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("p_last_name")) {
                    let errors = errorField.filter((x) => x != "p_last_name");
                    setErrorField([...errors]);
                  }
                  setUserData({
                    ...userData,
                    p_last_name: capitalizeFirstLetter(e.target.value),
                  });
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>Parent/Guardian Email*</div>
              <Input
                type={"email"}
                value={userData.p_email}
                className={
                  errorField !== undefined && errorField.includes("p_email")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("p_email")) {
                    let errors = errorField.filter((x) => x != "p_email");
                    setErrorField([...errors]);
                  }
                  setUserData({ ...userData, p_email: e.target.value });
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>
                Mobile Telephone Number*
              </div>
              <MaskedInput
                mask={"000-000-0000"}
                value={cellPhone}
                className={
                  errorField !== undefined &&
                    errorField.includes("p_cell_phone")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("p_cell_phone")) {
                    let errors = errorField.filter(
                      (x) => x != "p_cell_phone"
                    );
                    setErrorField([...errors]);
                  }
                  setCellPhone(e.target.value);
                }}
              />
            </Col>
            {regType == "true" && (
              <>

                <Col xs={24} sm={12}>
                  <div style={{ paddingBottom: "5px" }}>
                    Home Telephone Number
                  </div>
                  <MaskedInput
                    mask={"000-000-0000"}
                    value={homePhone}
                    className={
                      errorField !== undefined &&
                        errorField.includes("p_home_phone")
                        ? `common-input-error`
                        : `common-input`
                    }
                    onChange={(e) => {
                      if (errorField.includes("p_home_phone")) {
                        let errors = errorField.filter(
                          (x) => x != "p_home_phone"
                        );
                        setErrorField([...errors]);
                      }
                      setHomePhone(e.target.value);
                    }}
                  />
                </Col>
              </>
            )}


            {regType == "true" && (
              <>
                <Col xs={24} sm={12}>
                  <div style={{ paddingBottom: "5px" }}>Mobile Carrier*</div>
                  <Select
                    className={
                      errorField !== undefined &&
                        errorField.includes("p_relation")
                        ? `common-select-error`
                        : `common-select`
                    }
                    value={
                      userData.p_relation == undefined
                        ? null
                        : userData.p_relation
                    }
                    onSelect={(v) => {
                      if (errorField.includes("p_relation")) {
                        let errors = errorField.filter(
                          (y) => y != "p_relation"
                        );
                        setErrorField([...errors]);
                      }
                      setUserData({
                        ...userData,
                        p_relation: v,
                      });
                    }}
                  >
                    <Option value="Verizon">Verizon</Option>
                    <Option value="AT&T">AT&T</Option>
                    <Option value="T-Mobile">T-Mobile</Option>
                    <Option value="Sprint">Sprint</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Col>
              </>
            )}
          </Row>
        </div>
      </div>
      <div className="block" style={{ marginTop: 40, display: "none" }}>
        <div>
          <Row justify="space-between">
            <Col>
              <span className="title">Additional Contact Info</span>
            </Col>
            <Col>
              <span
                className="title"
                style={{ cursor: "pointer", display: "none" }}
              >
                Close
              </span>
            </Col>
          </Row>
          <Divider
            style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
          ></Divider>
        </div>
        <div style={{ marginTop: 20 }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>
                Additional Contact First Name
              </div>
              <Input
                value={userData.a_first_name}
                className="common-input"
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    a_first_name: capitalizeFirstLetter(e.target.value),
                  });
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>
                Additional Contact Last Name
              </div>
              <Input
                value={userData.a_last_name}
                className="common-input"
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    a_last_name: capitalizeFirstLetter(e.target.value),
                  });
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>
                Mobile Telephone Number
              </div>
              <MaskedInput
                mask={"000-000-0000"}
                value={aCellPhone}
                className={
                  errorField !== undefined &&
                    errorField.includes("a_cell_phone")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("a_cell_phone")) {
                    let errors = errorField.filter((x) => x != "a_cell_phone");
                    setErrorField([...errors]);
                  }
                  setACellPhone(e.target.value);
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>Home Telephone Number</div>
              <MaskedInput
                mask={"000-000-0000"}
                value={aHomePhone}
                className={
                  errorField !== undefined &&
                    errorField.includes("a_home_phone")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("a_home_phone")) {
                    let errors = errorField.filter((x) => x != "a_home_phone");
                    setErrorField([...errors]);
                  }
                  setAHomePhone(e.target.value);
                }}
              />
            </Col>

            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>Email Address</div>
              <Input
                type={"email"}
                value={userData.a_email}
                className={
                  errorField !== undefined && errorField.includes("a_email")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("a_email")) {
                    let errors = errorField.filter((x) => x != "a_email");
                    setErrorField([...errors]);
                  }
                  setUserData({ ...userData, a_email: e.target.value });
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>Relation to Camper</div>
              <Input
                value={userData.a_relation}
                className="common-input"
                onChange={(e) => {
                  setUserData({ ...userData, a_relation: e.target.value });
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      {regType == "true" && (
        <div className="block" style={{ marginTop: 40, marginBottom: 30 }}>
          <div>
            <Row justify="space-between">
              <Col>
                <span className="title">Emergency Contact</span>
              </Col>
              <Col>
                <span
                  className="title"
                  style={{ cursor: "pointer", display: "none" }}
                >
                  Close
                </span>
              </Col>
            </Row>
            <Divider
              style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
            ></Divider>
          </div>
          <div style={{ marginTop: 20 }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Contact First Name*</div>
                <Input
                  value={userData.c_first_name}
                  className={
                    errorField !== undefined &&
                      errorField.includes("c_first_name")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("c_first_name")) {
                      let errors = errorField.filter(
                        (x) => x != "c_first_name"
                      );
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      c_first_name: capitalizeFirstLetter(e.target.value),
                    });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Contact Last Name*</div>
                <Input
                  value={userData.c_last_name}
                  className={
                    errorField !== undefined &&
                      errorField.includes("c_last_name")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("c_last_name")) {
                      let errors = errorField.filter((x) => x != "c_last_name");
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      c_last_name: capitalizeFirstLetter(e.target.value),
                    });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Contact Email*</div>
                <Input
                  type="email"
                  value={userData.c_email}
                  className={
                    errorField !== undefined && errorField.includes("c_email")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("c_email")) {
                      let errors = errorField.filter((x) => x != "c_email");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, c_email: e.target.value });
                  }}
                />
              </Col>

              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>
                  Contact Phone Number*
                </div>
                <MaskedInput
                  mask={"000-000-0000"}
                  value={contactPhone}
                  className={
                    errorField !== undefined &&
                      errorField.includes("c_cell_phone")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("c_cell_phone")) {
                      let errors = errorField.filter(
                        (x) => x != "c_cell_phone"
                      );
                      setErrorField([...errors]);
                    }
                    setContactPhone(e.target.value);
                  }}
                />
              </Col>
            </Row>
          </div>
        </div>
      )}
      {regType == "true" && curDomain.includes("yaccamp") &&
        <div className="block" style={{ marginTop: 40, marginBottom: 30 }}>
          <div>
            <Row justify="space-between">
              <Col>
                <span className="title">Additional Information</span>
              </Col>
              <Col>
                <span
                  className="title"
                  style={{ cursor: "pointer", display: "none" }}
                >
                  Close
                </span>
              </Col>
            </Row>
            <Divider
              style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
            ></Divider>
          </div>
          <div style={{ marginTop: 20 }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Contact 2 First Name*</div>
                <Input
                  value={userData.a_c_first_name}
                  className={
                    errorField !== undefined &&
                      errorField.includes("a_c_first_name")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("a_c_first_name")) {
                      let errors = errorField.filter(
                        (x) => x != "a_c_first_name"
                      );
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      a_c_first_name: capitalizeFirstLetter(e.target.value),
                    });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Contact 2 Last Name*</div>
                <Input
                  value={userData.a_c_last_name}
                  className={
                    errorField !== undefined &&
                      errorField.includes("a_c_last_name")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("a_c_last_name")) {
                      let errors = errorField.filter((x) => x != "a_c_last_name");
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      a_c_last_name: capitalizeFirstLetter(e.target.value),
                    });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Contact 2 Email*</div>
                <Input
                  type="email"
                  value={userData.a_c_email}
                  className={
                    errorField !== undefined && errorField.includes("a_c_email")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("a_c_email")) {
                      let errors = errorField.filter((x) => x != "a_c_email");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, a_c_email: e.target.value });
                  }}
                />
              </Col>

              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>
                  Contact 2 Phone Number*
                </div>
                <MaskedInput
                  mask={"000-000-0000"}
                  value={acontactPhone}
                  className={
                    errorField !== undefined &&
                      errorField.includes("a_c_cell_phone")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("a_c_cell_phone")) {
                      let errors = errorField.filter(
                        (x) => x != "a_c_cell_phone"
                      );
                      setErrorField([...errors]);
                    }
                    setAContactPhone(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Park ID *</div>
                <Input
                  value={userData.a_park_id}
                  className={
                    errorField !== undefined &&
                      errorField.includes("a_park_id")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("a_park_id")) {
                      let errors = errorField.filter(
                        (x) => x != "a_park_id"
                      );
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      a_park_id: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Authorized pick up adults (separate by comas)*</div>
                <Input
                  value={userData.a_adults}
                  className={
                    errorField !== undefined &&
                      errorField.includes("a_adults")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("a_adults")) {
                      let errors = errorField.filter((x) => x != "a_adults");
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      a_adults: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Spouse or Other Parent or Guardian that your child may NOT be released to</div>
                <Input
                  value={userData.a_spouse}
                  className={
                    errorField !== undefined && errorField.includes("a_spouse")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("a_spouse")) {
                      let errors = errorField.filter((x) => x != "a_spouse");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, a_spouse: e.target.value });
                  }}
                />
              </Col>
              {userData.a_spouse &&
                <Col xs={24} sm={12}>
                  <div style={{ paddingBottom: "5px" }}>Is this situation court ordered?</div>
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={userData?.a_spouse_answer}
                    style={{ marginBottom: 10 }}
                    onChange={(e) => {
                      setUserData({ ...userData, a_spouse_anwser: e });
                    }}
                  />
                </Col>}

            </Row>
          </div>
        </div>}
    </div>
  );
};

export default StepTwo;
